'use strict';
import Masonry from "masonry-layout";


const initMasonry = () => {
        let msnry = new Masonry('.team-grid', {
            gutter: '.gutter-sizer',
            columnWidth: '.grid-sizer',
            itemSelector: '.grid-item',
            resize: true,
            percentPosition: true
        });

        //msnry.on('layoutComplete', hidePreloader());
};

jQuery(function () {
    initMasonry();
});